<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康档案"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="工作区">
                    <el-select v-model="search.GZDW" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="采油一厂" value="采油一厂"></el-option>
                        <el-option label="采油三厂" value="采油三厂"></el-option>
                        <el-option label="中油电能" value="中油电能"></el-option>
                        <el-option label="监督中心" value="监督中心"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="激活状态">
                    <el-select v-model="search.GZDW" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未激活" value="0"></el-option>
                        <el-option label="已激活" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问卷发送时间" label-width="100px">
                    <el-date-picker v-model="search.createdat" type="daterange" range-separator="至" clearable
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="建档状态">
                    <el-select v-model="search.GZDW" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未建档" value="0"></el-option>
                        <el-option label="已建档" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="回填状态">
                    <el-select v-model="search.GZDW" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="未发送" value="0"></el-option>
                        <el-option label="已发送未填写" value="1"></el-option>
                        <el-option label="已超时未填写" value="2"></el-option>
                        <el-option label="已填写" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索" >
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:80px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="标签分组">
                    <el-select v-model="search.KHBQ" placeholder="请选择" style="width:100%"
                               filterable allow-create default-first-option clearable
                    >
                        <el-option label="全部" value="全部"></el-option>
                        <el-option :label="item.name" :value="item.name" v-for="(item,index) in tagList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                    <el-button type="" plain @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search" style="background-color: #fff">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleAdd">发送激活短信</el-button>
                <el-button type="primary" size="mini" @click="handleAdd">发送基础问卷</el-button>
                <el-button type="primary" size="mini" @click="handleAdd">批量催办</el-button>
            </div>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%"
                          border @selection-change="handleSelectionChange">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex=='2'"> 女 </span>
                                <span v-if="scope.row.sex=='1'"> 男 </span>
                                <span v-if="scope.row.age">{{scope.row.age }} 岁</span>
                            </div>
                            <div><small> {{ maskIdCardRegex(scope.row.idCard) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                   @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="客户标签" width="100">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="200">
                        <template slot-scope="scope">
                            <div>{{ scope.row.companyName }}</div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="激活状态" width="" prop="activeStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.activeStatus" type="success">已激活</el-tag>
                            <el-tag v-else type="info">未激活</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近发送" width="140">
                        <template slot-scope="scope">
                            <div>{{ scope.row.questionnaireTime || '无' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近回填" prop="questionnaireStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.questionnaireStatus === '3'" type="success">已填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaireStatus === '2'" type="warning">已超时未填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaireStatus === '1'" type="warning">已发送未填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaireStatus === '0'" type="info">未发送</el-tag>
                            <el-tag v-else type="info">未激活</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="建档状态" width="140" prop="healthCreateStatus" >
                        <template slot-scope="scope">
                            <div>{{ scope.row.questionnaireTime || '无' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="建档时间" prop="healthCreateTime" width="140"
                                     :formatter="formatter.formatDateTime">
                    </el-table-column>
                    <el-table-column align="center" label="更新时间" width="140" prop="updateTime">
                    </el-table-column>
                    <el-table-column align="center" label="电话沟通" width="" prop="phoneStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.phoneStatus=='1'" type="success">已沟通</el-tag>
                            <el-tag v-else type="info">未沟通</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="240" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)">查看问卷</el-button>
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)">手工档案</el-button>
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)">查看档案</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"></data-form>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../../data/formatter"
    import dataForm from "./widgets/dataform";

    const makeInitSearch = () => {
        return {
            taskGroup: "",
            company: "",
            tagGroup: "全部",
            key: "name",
            value: "",
            report: "全部",
            createdat: [],
            type: "全部"
        }
    }


    export default {
        name: 'progress4',
        components: {
            dataForm,
        },
        data() {
            return {
                isFullIdCard: {},
                tagList: [
                    {
                        id: 1,
                        name: "五高二级"
                    },
                    {
                        id: 2,
                        name: "四高二级"
                    },
                    {
                        id: 3,
                        name: "三高二级"
                    },
                    {
                        id: 4,
                        name: "二高二级"
                    },
                    {
                        id: 5,
                        name: "一高二级"
                    },
                ],
                form: {
                    idList: [],
                    KHBQ: []
                },
                dialogVisible: false,
                formatter: formatter,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                idList: []
            }
        },
        methods: {
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleAdd() {
                if (this.form.idList.length == 0) {
                    this.$message({
                        message: '最少要选择一个对象',
                        type: 'warning'
                    });
                    return
                }
                this.form.KHBQ = []
                this.dialogVisible = true
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                }, 0);
            },
            handleRowChange(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            handleSelectionChange(val) {
                var that = this
                that.form.idList = []
                if (val.length) {
                    val.forEach(function (item) {
                        that.form.idList.push(item.id)
                    })
                }
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                const resp = await this.$http.get(`/healthProgress?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
